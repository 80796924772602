

import React from 'react';
import './About.css'

function About() {
  return (
    <div className="about-container">
      <h1>About Us</h1>
      <p>Founded by the Kancharla Family, we understand the challenges that come with property management and want to make the process as seamless as possible for property owners and tenants alike.</p>
      <p>Our team of experienced professionals is dedicated to providing high-quality property management services in Hyderabad, and we are committed to delivering the best possible experience to our clients.</p>    <div className="team">

        <p>Contact us today to learn more about how we can help you manage your property.</p>
      
      </div>
    </div>
  );
}

export default About;


/*    <div className="member">
          <img src="/images/team_member1.jpg" alt="Team Member 1" />
          <h3>John Kancharla</h3>
          <p>Co-founder & CEO</p>
        </div>
        <div className="member">
          <img src="/images/team_member2.jpg" alt="Team Member 2" />
          <h3>Jane Kancharla</h3>
          <p>Co-founder & CFO</p>
        </div>
        <div className="member">
          <img src="/images/team_member3.jpg" alt="Team Member 3" />
          <h3>Jack Kancharla</h3>
          <p>Co-founder & COO</p>
        </div>
        <div className="member">
          <img src="/images/team_member4.jpg" alt="Team Member 4" />
          <h3>Jill Kancharla</h3>
          <p>Co-founder & CMO</p>
        </div>
        <div className="member">
          <img src="/images/team_member5.jpg" alt="Team Member 5" />
          <h3>James Kancharla</h3>
          <p>Co-founder & CTO</p>
        </div>
        <div className="member">
          <img src="/images/team_member6.jpg" alt="Team Member 6" />
          <h3>Jessica Kancharla</h3>
          <p>Co-founder & Head of Operations</p>
        </div>
        <div className="member">
          <img src="/images/team_member7.jpg" alt="Team Member 7" />
          <h3>Jeff Kancharla</h3>
          <p>Co-founder & Head of Finance</p>
        </div>
        <div className="member">
          <img src="/images/team_member8.jpg" alt="Team Member 8" />
          <h3>Joan Kancharla</h3>
          <p>Co-founder & Head of Marketing</p>
        </div>
        <div className="member">
          <img src="/images/team_member9.jpg" alt="Team Member 9" />
          <h3>Jake Kancharla</h3>
          <p>Co-founder & Head of Technology</p>
        </div>
        <div className="member">
          <img src="/images/team_member10.jpg" alt="Team Member 10" />
          <h3>Jenny Kancharla</h3>
          <p>Co-founder & Head of Customer Relations</p>
        </div> */