import React from 'react';
import './Surveillance.css';

const Surveillance = () => {
  return (
    <div className="container">
      <h2>Surveillance Services</h2>
      <p>We offer a range of surveillance services to meet your property management needs.</p>
      <h3>Surveillance Packages</h3>
      <ul>
        <li>Basic Package: Includes a single camera and live monitoring</li>
        <li>Premium Package: Includes multiple cameras, motion detection, and alerts</li>
      </ul>
      <h3>Privacy and Legal Compliance</h3>
      <p>We understand the importance of privacy and legal compliance. Our surveillance services comply with local privacy laws and regulations. We obtain the necessary permits and inform tenants or occupants about the surveillance.</p>
      <h3>Surveillance Reports</h3>
      <p>We provide regular reports to property owners, including footage, images, and analysis of the surveillance data. This information can help property owners identify potential security threats and take corrective action.</p>
    </div>
  );
}

export default Surveillance;
