import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './assets/style.css'; 
import Header from "./components/Header";
import Hero from "./components/Hero";
import Services from "./components/Services";
import Surveillance from "./components/Surveillance";
import About from "./components/About";
import Testimonials from "./components/Testimonials";
import ContactForm from "./components/ContactForm";
import Footer from "./components/Footer";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Routes>
        <Route exact path="/" element={<Hero/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/testimonials" element={<Testimonials/>} />
          <Route path="/contact" element={<ContactForm/>} />
          <Route path="/surveillance" element={<Surveillance />}/>
          <Route path="/about" element={<About/>} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
