import React from 'react';
import './Testimonials.css'

function Testimonials() {
  return (
    <div className="testimonials-container">
      <div className="testimonial-card">
        <div className="testimonial-avatar">
          <img src="avatar1.jpg" alt="Testimonial Avatar" />
        </div>
        <div className="testimonial-content">
          <p className="testimonial-text">
            "Atlasranger has been a game-changer for our property management. Their services are
            top-notch, and their team is always responsive and professional. Highly recommended!"
          </p>
          <p className="testimonial-author">
            <strong>Dheeraj K</strong>, Property Owner
          </p>
        </div>
      </div>
      <div className="testimonial-card">
        <div className="testimonial-avatar">
          <img src="avatar2.jpg" alt="Testimonial Avatar" />
        </div>
        <div className="testimonial-content">
          <p className="testimonial-text">
            "As a property owner, I have been using Atlasranger for the past year, and it has been
            a great experience. Their services are efficient, reliable, and cost-effective. I
            highly recommend them to anyone looking for property management solutions."
          </p>
          <p className="testimonial-author">
            <strong>Pooja M</strong>, Real Estate Investor
          </p>
        </div>
      </div>
      <div className="testimonial-card">
        <div className="testimonial-avatar">
          <img src="avatar3.jpg" alt="Testimonial Avatar" />
        </div>
        <div className="testimonial-content">
          <p className="testimonial-text">
            "Atlasranger has made managing my rental properties a breeze. Their team takes care
            of everything, from marketing and tenant screening to repairs and financial reporting.
            I highly recommend their services for any property owner looking for peace of mind."
          </p>
          <p className="testimonial-author">
            <strong>Rahul</strong>, Landlord
          </p>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
