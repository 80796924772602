import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import './Header.css';

function Header() {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Atlas Ranger" />
      </div>
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/testimonials">Testimonials</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
      </nav>
      <div className="contact-info">
        <p>Call Us: +91 9985777775</p>
        <a href="contact">
          <button>Contact Us</button>
        </a>
      </div>
    </header>
  );
}

export default Header;