import React, { useState } from 'react';
import './ContactForm.css'
import fetch from 'isomorphic-fetch';

const ContactForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Prepare form data
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('message', message);
  
    // Send POST request
    try {
      const response = await fetch('/v1/contact', {
        method: 'POST',
        body: formData
      });
  
      if (response.ok) {
        // Handle successful response
        console.log('Contact form data sent successfully!');
        setFormSubmitted(true); // Set formSubmitted state to true after successful form submission
      } else {
        // Handle error response
        console.error('Failed to send contact form data');
      }
    } catch (error) {
      // Handle fetch error
      console.error('Failed to send contact form data:', error);
    }
  }
  

  return (
    <div className="contact-us">
      {formSubmitted ? (
       (<div>
        <h1>Thank You!</h1>
        <p>We have received your contact form submission and will get back to you as soon as possible.</p>
        <p>Thank you for reaching out to us!</p>
      </div>)
      ) : (      
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name:</label>
          <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Phone Number:</label>
          <input type="phone" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Message:</label>
          <textarea className="form-control" value={message} onChange={(e) => setMessage(e.target.value)} />
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
      )}
    </div>
  );
}

export default ContactForm;
