import React from 'react';
import './Services.css'

function Services() {
  return (
    <div className="services-container">
      <div className="service-card">
        <img src="property-marketing.png" alt="Property Marketing" />
        <h3>Property Marketing</h3>
        <p>We can help property owners market their properties by listing them on various online platforms, including our web application, and promoting them through digital marketing.</p>
      </div>
      <div className="service-card">
        <img src="property-inspection.png" alt="Property Inspection" />
        <h3>Property Inspection</h3>
        <p>We can perform regular inspections of the properties we manage to ensure they are well-maintained and identify any repair or maintenance issues.</p>
      </div>
      <div className="service-card">
        <img src="tenant-screening.png" alt="Tenant Screening" />
        <h3>Tenant Screening</h3>
        <p>We can screen prospective tenants to ensure they are financially stable and have a good rental history to minimize the risk of default or eviction.</p>
      </div>
      <div className="service-card">
        <img src="rent-collection.png" alt="Rent Collection" />
        <h3>Rent Collection</h3>
        <p>We can collect rent from tenants on behalf of property owners and ensure timely payments.</p>
      </div>
      <div className="service-card">
        <img src="maintenance-repairs.png" alt="Maintenance and Repairs" />
        <h3>Maintenance and Repairs</h3>
        <p>We can arrange for maintenance and repairs of properties and ensure that they are completed promptly and efficiently.</p>
      </div>
      <div className="service-card">
        <img src="lease-management.png" alt="Lease Management" />
        <h3>Lease Management</h3>
        <p>We can manage lease agreements, including drafting, negotiating, and renewing lease agreements, ensuring compliance with legal requirements and tracking lease expirations.</p>
      </div>
      <div className="service-card">
        <img src="financial-reporting.png" alt="Financial Reporting" />
        <h3>Financial Reporting</h3>
        <p>We can provide financial reporting to property owners, including income and expense statements, cash flow statements, and balance sheets, to help them track their investment performance.</p>
      </div>
      <div className="service-card">
        <img src="tenant-management.png" alt="Tenant Management" />
        <h3>Tenant Management</h3>
        <p>We can handle all tenant-related issues, including responding to inquiries and complaints, addressing maintenance issues, and managing lease renewals and terminations.</p>
      </div>
      <div className="service-card">
        <img src="surveillance-services.png" alt="Surveillance Services" />
        <h3>Surveillance Services</h3>
        <p>We can provide surveillance services to property owners who want to monitor their properties remotely. This can include installation of cameras and other surveillance equipment, monitoring of the live feed, and providing regular reports to property owners.</p>
      </div>
      <div className="service-card">
        <img src="surveillance-package.png" alt="Surveillance Package" />
        <h3>Surveillance Package</h3>
        <p>We can offer different surveillance packages that cater to different needs and budgets. For instance, we can provide regular reports to help you identify potential security threats and take corrective action.
        </p>
      </div>
      <div className="service-card">
        <img src="additional-services.png" alt="Additional Services" />
        <h3>Additional Services</h3>
        <p>We offer additional services that complement our surveillance services, such as security audits, risk assessments, and emergency response planning.
        </p>
    </div>
    </div>
  );
}

export default Services;
