import React from 'react';
import './Hero.css';

function Hero() {
  return (
    <div className="hero">
      <h2>Revolutionize Your Property Investments with Our Dynamic Property Management Solutions!</h2>
      <p>Introducing our revolutionary property management services that take your investment experience to new heights! Trust us to be your reliable, 
        efficient, and results-driven partner in maximizing your property investments. 
        Our comprehensive services include cutting-edge property marketing strategies, 
        regular property inspections, expert tenant screening, streamlined rent collection processes, prompt maintenance and repair services, and expert lease management services. 
        <br/><br/>
        We also provide comprehensive financial reporting and tenant management solutions, allowing you to sit back and watch your investment thrive. But that's not all! Our advanced surveillance services and packages ensure enhanced security and privacy compliance for your properties, while our additional services such as security audits, risk assessments, and emergency response planning offer added peace of mind. It's time to elevate your investment game with our revolutionary property management solutions. Get in touch with us today!</p>
      
    </div>
  );
}

export default Hero;


/*
<ul>
        <li>Property Promotion: Our cutting-edge marketing strategies and platforms help your properties stand out in a crowded market.</li>
        <li>Property Inspection: Our proactive approach to inspections ensures timely identification of potential issues, preventing costly repairs down the line.</li>
        <li>Tenant Screening: We utilize advanced tenant screening methods to minimize your risk of default or eviction.</li>
        <li>Rent Collection: Our innovative rent collection process ensures timely payments, maximizing your cash flow and profit margins.</li>
        <li>Maintenance and Repairs: Our responsive and efficient maintenance and repair services keep your properties in top-notch condition, increasing their value over time.</li>
        <li>Lease Management: Our expert lease management services include personalized drafting, negotiation, and renewal strategies to protect your best interests.</li>
        <li>Financial Reporting: Our comprehensive financial reporting and analysis give you the insights you need to make informed investment decisions.</li>
        <li>Tenant Management: Our dedicated team handles all tenant-related matters, ensuring a positive and stress-free experience for both you and your tenants.</li>
        <li>Surveillance Services: Our state-of-the-art surveillance services allow you to keep a watchful eye on your properties from anywhere in the world, enhancing security and peace of mind.</li>
        <li>Surveillance Packages: Choose from our various surveillance packages tailored to your unique needs and budget, ranging from basic to premium options.</li>
        <li>Privacy and Legal Compliance: Our surveillance services comply with local privacy laws and regulations, including permits and tenant notifications, protecting you from legal risks and penalties.</li>
        <li>Surveillance Reports: Our detailed reports provide invaluable footage, images, and analysis, enabling you to identify potential security threats and take proactive measures to prevent them.</li>
        <li>Additional Services: We offer supplementary services such as security audits, risk assessments, and emergency response planning, giving you the ultimate peace of mind and confidence in your investment.</li>
      </ul>*/