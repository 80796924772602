import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <a href="contact">
          <button className="footer-button">Let's Talk</button>
        </a>
        <p>If you have any questions, please don't hesitate to contact us:</p>
        <ul>
          <li>Email: atlasranger4u@gmail.com</li>
          <li>Phone: +91-9985-777-775</li>
        </ul>
      </div>
     
      <div className="footer-bottom">
        <p>© 2023 Property Management Hyderabad. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;


/*
 <div className="footer-social">
        <h3>Follow Us</h3>
        <ul>
          <li><a href="https://www.facebook.com/propertymanagementhyd">Facebook</a></li>
          <li><a href="https://www.instagram.com/propertymanagementhyd">Instagram</a></li>
          <li><a href="https://www.twitter.com/propertymanagementhyd">Twitter</a></li>
        </ul>
      </div>*/